/**
 * External dependencies.
 */
import React from "react";
import { ApolloProvider } from "@apollo/client";
import { CartProvider } from "../views/cart/CartContext";
import { ToastContainer } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
/**
 * Internal dependencies.
 */
import client from "./client";
import Messenger from "../components/Messenger/Messenger.component";
/**
 * Root Element which is a wrapper to the app.
*
* @param {Object} element App element.
*
* @return {*}
*/
export const wrapRootElement = ({ element }) => (
  <ApolloProvider client={client}>
    <CartProvider>
      {element}
      <Messenger />
      <ToastContainer />
    </CartProvider>
  </ApolloProvider>
);