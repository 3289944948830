import React, { useEffect } from "react"

const facebookAppId = 488089174564174

function Messenger(props) {
  useEffect(() => {
    if(typeof window != 'undefined') {
      window.fbAsyncInit = function() {
        FB.init({
          xfbml: true,
          version: "v13.0"
        })
      };
  
      (function(d, s, id) {
        let js, fjs = d.getElementsByTagName(s)[0]
        if (d.getElementById(id)) {
          return
        }
        js = d.createElement(s); js.id = id
        js.src = "https://connect.facebook.net/en_US/sdk/xfbml.customerchat.js"
        fjs.parentNode.insertBefore(js, fjs)
      }(document, "script", "facebook-jssdk"))
    }
  })


  return (
    <>
      <div id="fb-root" />
      <div
        className="fb-customerchat"
        attribution="setup_tool"
        page_id={facebookAppId}
      />
    </>
  )
}

export default Messenger