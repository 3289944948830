import fetch from "isomorphic-fetch"
import { ApolloClient, createHttpLink, InMemoryCache, ApolloLink, from } from "@apollo/client"
import { navigate } from "gatsby"
import { isEmpty } from "lodash"
import { onError } from "@apollo/client/link/error"

const afterwareLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(response => {
    const context = operation.getContext()
    const {
      response: { headers }
    } = context
    const session = context.response.headers.get("woocommerce-session")

    if (session) {
      // Remove session data if session destroyed.
      if ("false" === session) {
        localStorage.removeItem("woo-session")

        // Update session new data if changed.
      } else if (localStorage.getItem("woo-session") !== session) {
        localStorage.setItem("woo-session", headers.get("woocommerce-session"))
      }
    }

    return response
  })
})

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(error => {
      const { locations, message, path } = error
      console.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      if (message === "Internal server error") {
        localStorage.removeItem("auth")
        sessionStorage.removeItem("isLogged")
        if(window.location.pathname === "/") {
          window.location.reload()
        }else {
          navigate("/")
        }
      }
    })

  if (networkError) console.warn(`[Network error]: ${networkError}`)
})
const httpLink = createHttpLink({
  uri: "https://admin.backa.com.au/graphql",
  // uri: "https://74.arrowhitech.net/g3/backa/graphql",
  fetch
})

const midd = new ApolloLink((operation, forward) => {
  let headersData = null

  const session = localStorage.getItem("woo-session")
  if (!isEmpty(session)) {
    headersData = {
      "woocommerce-session": `Session ${session}`
    }
  }
  const auth = JSON.parse(localStorage.getItem("auth"))
  const token = !isEmpty(auth) ? auth.authToken : null
  // const auth=JSON.parse(auth1)

  if (!isEmpty(token)) {
    headersData = {
      ...headersData,
      authorization: token ? `Bearer ${token}` : ""
    }
  }

  if (!isEmpty(headersData)) {
    operation.setContext(() => ({
      headers: headersData
    }))
  }

  return forward(operation)
})

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: midd.concat(afterwareLink.concat(from([errorLink, httpLink])))
})

export default client
