import { useMutation, useQuery } from "@apollo/client"
import React, { createContext, useContext, useEffect, useReducer, useState } from "react"
import { GET_CART } from "../../graphql/queries/cart/getCart"
import { CLEAR_CART } from "../../graphql/mutations/cart/clearCart"
import { randomString } from "../../helpers/randomString"
import { toast } from "react-toastify"
export const cartContext = createContext({})

export const ADD_MESSAGE = "add-message"
export const REMOVE_MESSAGE = "remove-message"
export const CART_ITEM_LOADING = "cart-loading"
export const CART_TOTAL_LOADING = "cart-total-loading"
export const CART_UPDATABLE = "cart-updatable"
export const CART_IS_CART_EMPTY = "cart-is-empty"
export const UPDATE_DATA_USER = "update-data-user"
const initState = {
  messages: [],
  cartItemLoading: false,
  cartTotalLoading: false,
  isCartEmpty: false,
  updatable: false,
  dataUser: {}
}
function reducer(state, action) {
  switch (action.type) {
    case ADD_MESSAGE:
      return {
        ...state,
        messages: [...state.messages, action.payload]
      }
    case REMOVE_MESSAGE:
      return {
        ...state,
        messages: state.messages.filter((_, i) => i !== action.payload)
      }
    case CART_ITEM_LOADING:
      return {
        ...state,
        cartItemLoading: action.payload
      }
    case CART_TOTAL_LOADING:
      return {
        ...state,
        isCartEmpty: false,
        cartTotalLoading: action.payload
      }
    case CART_UPDATABLE:
      return {
        ...state,
        isCartEmpty: false,
        updatable: action.payload
      }
    case CART_IS_CART_EMPTY:
      return {
        ...state,
        isCartEmpty: action.payload
      }
    case UPDATE_DATA_USER:
      return {
        ...state,
        dataUser: action.payload
      }
    default:
      return state
  }
}
export const CartProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initState)
  const [cartItems, setCartItems] = useState([])
  const [cartDetail, setCartDetail] = useState()
  const [valuePayment, setValuePayment] = useState("")
  const [shippingChosen, setShippingChosen] = useState("")
  const [check, setCheck] = useState(false)
  const [isAddToCart, setIsAddToCart] = useState(false)
  const { loading, error, data, refetch } = useQuery(GET_CART, {
    onCompleted: data => {
      setCartDetail(data?.cart)
      setShippingChosen(
        data?.cart &&
          Array.isArray(data?.cart?.chosenShippingMethods) &&
          data?.cart?.chosenShippingMethods.length > 0
          ? data?.cart?.chosenShippingMethods[0]
          : ""
      )
      setCartItems(data?.cart?.contents?.nodes)
      if(isAddToCart) {
        toast.success("Add to cart success!")
        setIsAddToCart(false)
      }
    },
    onError: err => console.error(err),
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true
  })
  const [clearCart, { loading: clearLoading }] = useMutation(CLEAR_CART, {
    variables: {
      input: {
        clearPersistentCart: false,
        clientMutationId: randomString()
      }
    },
    onError: err => toast.error(err)
  })

  useEffect(() => {
    refetch()
  }, [])
  return (
    <cartContext.Provider
      value={{
        state,
        dispatch,
        refetch,
        cartItems,
        setCartItems,
        error,
        loading,
        cartDetail,
        check,
        setCheck,
        clearCart,
        clearLoading,
        valuePayment,
        setValuePayment,
        shippingChosen,
        setShippingChosen,
        isAddToCart,
        setIsAddToCart
      }}
    >
      {children}
    </cartContext.Provider>
  )
}

export const useCartContext = () => {
  return useContext(cartContext)
}

export const useCartDispatch = () => {
  const { dispatch } = useCartContext()
  return dispatch
}
