exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-business-opportunities-js": () => import("./../../../src/pages/business-opportunities.js" /* webpackChunkName: "component---src-pages-business-opportunities-js" */),
  "component---src-pages-cart-index-jsx": () => import("./../../../src/pages/cart/index.jsx" /* webpackChunkName: "component---src-pages-cart-index-jsx" */),
  "component---src-pages-checkout-billing-details-jsx": () => import("./../../../src/pages/checkout/BillingDetails.jsx" /* webpackChunkName: "component---src-pages-checkout-billing-details-jsx" */),
  "component---src-pages-checkout-index-jsx": () => import("./../../../src/pages/checkout/index.jsx" /* webpackChunkName: "component---src-pages-checkout-index-jsx" */),
  "component---src-pages-checkout-payment-jsx": () => import("./../../../src/pages/checkout/Payment.jsx" /* webpackChunkName: "component---src-pages-checkout-payment-jsx" */),
  "component---src-pages-checkout-your-order-jsx": () => import("./../../../src/pages/checkout/YourOrder.jsx" /* webpackChunkName: "component---src-pages-checkout-your-order-jsx" */),
  "component---src-pages-contact-index-jsx": () => import("./../../../src/pages/contact/index.jsx" /* webpackChunkName: "component---src-pages-contact-index-jsx" */),
  "component---src-pages-find-a-retailer-index-jsx": () => import("./../../../src/pages/find-a-retailer/index.jsx" /* webpackChunkName: "component---src-pages-find-a-retailer-index-jsx" */),
  "component---src-pages-forgot-password-index-js": () => import("./../../../src/pages/forgot-password/index.js" /* webpackChunkName: "component---src-pages-forgot-password-index-js" */),
  "component---src-pages-gallery-index-jsx": () => import("./../../../src/pages/gallery/index.jsx" /* webpackChunkName: "component---src-pages-gallery-index-jsx" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-my-account-addresses-js": () => import("./../../../src/pages/my-account/addresses.js" /* webpackChunkName: "component---src-pages-my-account-addresses-js" */),
  "component---src-pages-my-account-edit-account-js": () => import("./../../../src/pages/my-account/edit-account.js" /* webpackChunkName: "component---src-pages-my-account-edit-account-js" */),
  "component---src-pages-my-account-edit-address-js": () => import("./../../../src/pages/my-account/edit-address.js" /* webpackChunkName: "component---src-pages-my-account-edit-address-js" */),
  "component---src-pages-my-account-index-js": () => import("./../../../src/pages/my-account/index.js" /* webpackChunkName: "component---src-pages-my-account-index-js" */),
  "component---src-pages-my-account-orders-js": () => import("./../../../src/pages/my-account/orders.js" /* webpackChunkName: "component---src-pages-my-account-orders-js" */),
  "component---src-pages-order-success-index-js": () => import("./../../../src/pages/order-success/index.js" /* webpackChunkName: "component---src-pages-order-success-index-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-quick-order-index-jsx": () => import("./../../../src/pages/quick-order/index.jsx" /* webpackChunkName: "component---src-pages-quick-order-index-jsx" */),
  "component---src-pages-recipes-index-js": () => import("./../../../src/pages/recipes/index.js" /* webpackChunkName: "component---src-pages-recipes-index-js" */),
  "component---src-pages-shop-index-jsx": () => import("./../../../src/pages/shop/index.jsx" /* webpackChunkName: "component---src-pages-shop-index-jsx" */),
  "component---src-pages-sustainability-js": () => import("./../../../src/pages/sustainability.js" /* webpackChunkName: "component---src-pages-sustainability-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-wholesale-login-index-js": () => import("./../../../src/pages/wholesale-login/index.js" /* webpackChunkName: "component---src-pages-wholesale-login-index-js" */),
  "component---src-pages-wholesale-registration-index-js": () => import("./../../../src/pages/wholesale-registration/index.js" /* webpackChunkName: "component---src-pages-wholesale-registration-index-js" */),
  "component---src-templates-product-index-js": () => import("./../../../src/templates/product/index.js" /* webpackChunkName: "component---src-templates-product-index-js" */)
}

