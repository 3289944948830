import { gql } from "@apollo/client"

export const CART_ITEM_FIELDS = gql`
  fragment CartItemFields on CartItem {
    key
    quantity
    subtotal
    subtotalTax
    variation {
      attributes {
        name
        value
      }
      node {
        name
        id
        databaseId
      }
    }
    product {
      node {
        databaseId
        link
        ... on SimpleProduct {
          id
          name
          stockStatus
          stockQuantity
          regularPrice(format: RAW)
          salePrice(format: RAW)
          onSale
          featuredImage {
            node {
              sourceUrl
            }
          }
          description
          galleryImages {
            nodes {
              sourceUrl
            }
          }
          slug
        }
        ... on VariableProduct {
          id
          name
          stockStatus
          stockQuantity
          price(format: RAW)
          regularPrice(format: RAW)
          salePrice(format: RAW)
          onSale
          featuredImage {
            node {
              sourceUrl
            }
          }
          description
          galleryImages {
            nodes {
              sourceUrl
            }
          }
          slug
        }
      }
    }
  }
`

export const CART_SIMPLE_FIELDS = gql`
  fragment cartSimpleFields on Cart {
    isEmpty
    contents {
      nodes {
        quantity
        product {
          node {
            databaseId
            id
            name
          }
        }
        variation {
          node {
            databaseId
          }
        }
      }
    }
  }
`