// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// normalize CSS across browsers
import "./src/css/normalize.css"

// custom CSS styles
import "./src/css/style.css"
import "./src/css/global.css"

// slick
import "./src/assets/slick-carousel/slick.css"
import "./src/assets/slick-carousel/slick-theme.css"
import "./src/assets/awe/css/all.min.css"

export { wrapRootElement } from "./src/gatsby-plugin-apollo/wrap-root-element"
export const onRouteUpdate = () => {
    if (typeof window !== `undefined`) { window.scrollTo(0, 0) }
}

export const shouldUpdateScroll = args => {
    return false;
};