import { gql } from "@apollo/client"
export const CLEAR_CART = gql`
  mutation clearCart($input: EmptyCartInput!) {
    emptyCart(input: $input) {
      deletedCart {
        availableShippingMethods {
          packageDetails
          rates {
            label
            instanceId
            id
            methodId
            label
            cost
          }
          supportsShippingCalculator
        }
      }
    }
  }
`
