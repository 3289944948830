import { gql } from "@apollo/client"
import { CART_ITEM_FIELDS } from "../../fragments"
export const GET_CART = gql`
${CART_ITEM_FIELDS}
query getCart {
    cart {
      appliedCoupons {
        code
        description
        discountAmount(excludeTax: true)
        discountTax
      }
      availableShippingMethods {
        packageDetails
        rates {
          label
          instanceId
          id
          methodId
          label
          cost
        }
        supportsShippingCalculator
      }
      chosenShippingMethods
      contentsTax
      contentsTotal
      discountTax
      discountTotal
      displayPricesIncludeTax
      feeTax
      feeTotal
      fees {
        amount
        id
        name
        taxClass
        taxable
        total
      }
      isEmpty
      needsShippingAddress
      shippingTax
      shippingTotal
      subtotal
      subtotalTax
      total
      totalTax
      totalTaxes {
        amount
        id
        isCompound
        label
      }
      contents {
        nodes {
          ...CartItemFields
        }
      }
    }
}
`